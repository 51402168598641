import { atom } from 'jotai';

import {
  Company,
  InvoiceQueryFilters,
  InvoiceSortColumns,
  InvoiceStages,
  PaymentStatus,
  SortOrder,
  User
} from 'openapi';

import { INITIAL_PAGINATION_PROPS } from 'utils/constants/paginations';
import { CounterpartyFilterType } from 'utils/enums/CounterpartyFilterType';
import { FilterType } from 'utils/enums/FilterType';

export const invoicesPaginationAtom = atom<{
  page: number;
  pageSize: number;
}>({
  page: INITIAL_PAGINATION_PROPS.page,
  pageSize: INITIAL_PAGINATION_PROPS.pageSize
});

export const userAtom = atom<User | null>(null);
export const companyAtom = atom<Company>();
export const filterTypeAtom = atom<FilterType | CounterpartyFilterType>(
  FilterType.Initial
);
export const isSavingAtom = atom<boolean>(false);

const INITIAL_FILTER = {
  sortBy: InvoiceSortColumns.INSERTED_AT,
  sortOrder: SortOrder.DESC,
  amount: {},
  invoiceDate: {},
  uploadedDate: {}
};

export const invoicesFiltersAtom = atom<InvoiceQueryFilters>({
  ...INITIAL_FILTER
});

export const invoicesFiltersAtomForApproval = atom<InvoiceQueryFilters>(
  (get) => ({
    ...INITIAL_FILTER,
    stages: [InvoiceStages.VALIDATED],
    approvers: get(userAtom) ? [get(userAtom)?.name || ''] : undefined,
    isForApproval: true
  })
);

export const invoicesFiltersAtomForAccounting = atom<InvoiceQueryFilters>({
  ...INITIAL_FILTER,
  isBooked: false,
  stages: [InvoiceStages.APPROVED]
});

export const invoicesFiltersAtomForPayment = atom<InvoiceQueryFilters>({
  ...INITIAL_FILTER,
  stages: [InvoiceStages.APPROVED],
  paymentStatus: PaymentStatus.NOT_PAID
});

export const areFiltrsVisibleAtom = atom<boolean>(false);
export const selectedCompanyIdAtom = atom<number>();
export const isExportButtonDisabledAtom = atom<boolean>(false);
export const exportButtonTooltipTextAtom = atom<string>('');
