import { Box } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';

import {
  CurrencyDropDown,
  Invoice,
  InvoiceSection,
  InvoiceExpenseType,
  InvoicePatch
} from 'openapi';

import {
  getInvoiceDataDefaultValues,
  getInvoiceDataFormValues,
  getSaveInvoiceDataFormValues
} from 'utils/helpers/invoiceHelpers';
import {
  InvoiceDetails,
  InvoiceSectionKey
} from 'utils/interfaces/InvoiceProps';

import { ActionButtons } from './ActionButtons';
import { EditInvoiceDataForm } from './EditInvoiceDataForm';
import { AdditionalInvoiceFields } from './ReceiverForm';

interface InvoiceDataFormProps {
  currentInvoice: Invoice;
  triggerReprompt: boolean;
  handleSaveSection: (values: InvoicePatch, section: InvoiceSectionKey) => void;
  handleVerify: (
    values: Invoice & AdditionalInvoiceFields,
    section: InvoiceSectionKey
  ) => void;
  handleBack: (section: InvoiceSection) => void;
}

export const InvoiceDataForm = ({
  currentInvoice,
  triggerReprompt,
  handleSaveSection,
  handleVerify,
  handleBack
}: InvoiceDataFormProps) => {
  const location = useLocation();
  const { getCurrencies, getExpenseTypes, getAllTagsByCompanyId } =
    useAdditionalFieldsController();

  const [currencies, setCurrencies] = useState<CurrencyDropDown[]>();
  const [expenseTypeOptions, setExpenseTypeOptions] =
    useState<InvoiceExpenseType[]>();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [availableTags, setAvailableTags] = useState<string[]>([]);
  const isVerifiedRef = useRef<boolean>(isVerified);
  const triggerRepromptRef = useRef<boolean>(triggerReprompt);

  const fetchCurrencies = useCallback(async () => {
    const currenciesResponse = await getCurrencies();
    setCurrencies(currenciesResponse);
  }, [getCurrencies]);

  const fetchExpenseTypes = useCallback(async () => {
    const expenseTypes = await getExpenseTypes(
      Number(currentInvoice.companyId)
    );
    setExpenseTypeOptions(expenseTypes);
  }, [getExpenseTypes, currentInvoice.companyId]);

  const fetchAvailableTags = useCallback(async () => {
    const tagsResponse = await getAllTagsByCompanyId(
      Number(currentInvoice.companyId)
    );
    setAvailableTags(tagsResponse);
  }, [getAllTagsByCompanyId, currentInvoice.companyId]);

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    watch,
    trigger,
    setValue
  } = useForm<InvoiceDetails>({
    mode: 'onChange',
    defaultValues: getInvoiceDataDefaultValues(currentInvoice)
  });

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const handleVerifySection = () => {
    isVerifiedRef.current = true;
    setIsVerified(true);
    handleVerify(
      getInvoiceDataFormValues(
        currentInvoice,
        expenseTypeOptions || [],
        getValues
      ),
      InvoiceSection.INVOICE_DATA
    );
  };

  useEffect(() => {
    triggerRepromptRef.current = triggerReprompt;
  }, [triggerReprompt]);

  useEffect(() => {
    fetchCurrencies();
    fetchExpenseTypes();
    fetchAvailableTags();
  }, [fetchCurrencies, fetchExpenseTypes, fetchAvailableTags]);

  useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  useEffect(() => {
    trigger();
  }, [formState.isValid, currentInvoice]);

  useEffect(() => {
    const handleBeforeUnload = () =>
      handleSaveSection(
        getSaveInvoiceDataFormValues(currentInvoice, getValues),
        InvoiceSection.INVOICE_DATA
      );
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [handleSaveSection, getSaveInvoiceDataFormValues]);

  useEffect(() => {
    return () => {
      if (isVerifiedRef.current || triggerRepromptRef.current) {
        return;
      }

      handleSaveSection(
        getSaveInvoiceDataFormValues(currentInvoice, getValues),
        InvoiceSection.INVOICE_DATA
      );
    };
  }, [location.pathname, handleSaveSection, getSaveInvoiceDataFormValues]);

  useEffect(() => {
    if (getValues('expenseType') === currentInvoice.expenseType?.id) {
      return;
    }
    setValue('expenseType', Number(currentInvoice.expenseType?.id));
  }, [currentInvoice, setValue]);

  return (
    <Box sx={{ pt: 2.5 }}>
      <form onSubmit={handleSubmit(handleVerifySection)}>
        <ActionButtons
          shouldVerify
          handleBack={() => handleBack(InvoiceSection.SUPPLIER)}
          isFormValid={isFormValid}
        />
        {currencies && expenseTypeOptions && (
          <EditInvoiceDataForm
            currentInvoice={currentInvoice}
            control={control}
            expenseTypeOptions={expenseTypeOptions}
            currencies={currencies}
            availableTags={availableTags}
            watch={watch}
            setAvailableTags={setAvailableTags}
          />
        )}
      </form>
    </Box>
  );
};
