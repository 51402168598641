export const modal = {
  '& .MuiPaper-root': {
    width: '100%'
  }
};

export const dialogTitle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.5rem 1.5rem ',
  borderBottom: '1px solid #0000001f'
};

export const iconButton = {
  '&:hover': { backgroundColor: 'transparent' }
};

export const closeIcon = {
  color: 'black'
};

export const dialogContent = {
  height: '100%',
  paddingTop: '20px'
};
