import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { Box, Chip, Skeleton, Tooltip } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import moment from 'moment';
import { UseFormGetValues } from 'react-hook-form';

import {
  CounterpartyDropDown,
  DocumentType,
  Invoice,
  InvoiceExpenseType,
  InvoicePatch,
  InvoiceQueryFilters,
  InvoiceSection,
  InvoiceSortColumns,
  InvoiceStages,
  PaymentChannel,
  PaymentStatus,
  SortOrder
} from 'openapi';

import {
  ACTIONS,
  APPROVERS,
  COLUMNS_DEFAULT_OPTIONS,
  DATE_FORMATS,
  INITIAL_IBAN_ITEM,
  IS_PAID
} from 'utils/constants/constants';
import { FILTER_BAR_HEIGHT } from 'utils/constants/filters';
import {
  VAT_NUMBER_PREFIX_BULGARIA,
  INITIAL_SORT,
  PAYMENT_CHANNELS_TRANSLATION_KEYS
} from 'utils/constants/invoices';
import { CreatedDocument, InvoiceDetails } from 'utils/interfaces/InvoiceProps';
import { TranslateFunction } from 'utils/interfaces/Translations';

import { sortDates } from './dates';

export const isVatNumberEqualCRN = (
  vatNumber: string,
  companyRegNumber?: string
) => {
  if (
    vatNumber &&
    companyRegNumber &&
    vatNumber.startsWith(VAT_NUMBER_PREFIX_BULGARIA)
  ) {
    return !(vatNumber === `BG${companyRegNumber}`);
  }

  return false;
};

export const conditions: Record<
  string,
  (value: string, values: any) => boolean
> = {
  companyRegistrationNumber: (
    value: string | undefined,
    values: any
  ): boolean =>
    (value && value.length <= 0) ||
    isVatNumberEqualCRN(values.vatNumber, value),
  dueDate: (value: string) => value.length <= 0,
  vatNumber: (value: string, values: any): boolean =>
    value.length <= 0 ||
    isVatNumberEqualCRN(value, values.companyRegistrationNumber)
};

export const decimalFormater = (value: number | null): number | string => {
  return value !== null ? Number(value).toFixed(2) : '0.00';
};

export const getInvoiceColumns = (
  isInAllCompanies: boolean,
  translate: TranslateFunction
): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: InvoiceSortColumns.STAGE,
      headerName: translate('labels.stage'),
      minWidth: 110,
      maxWidth: 110,
      headerAlign: 'center',
      align: 'center'
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: InvoiceSortColumns.SHORT_COMPANY_NAME,
      headerName: translate('labels.shortCompanyName'),
      minWidth: 100,
      maxWidth: 130,
      headerAlign: 'left',
      align: 'left'
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: InvoiceSortColumns.COUNTERPARTY_NAME,
      headerName: translate('labels.counterparty'),
      minWidth: 275,
      headerAlign: 'left',
      align: 'left'
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: InvoiceSortColumns.INVOICE_NUMBER,
      headerName: translate('labels.number'),
      headerAlign: 'left',
      align: 'left',
      minWidth: 120,
      maxWidth: 180
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: InvoiceSortColumns.INVOICE_DATE,
      headerName: translate('labels.date'),
      headerAlign: 'left',
      align: 'left',
      minWidth: 100,
      maxWidth: 100,
      sortComparator: sortDates
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: InvoiceSortColumns.INVOICE_AMOUNT,
      headerName: translate('labels.amount'),
      minWidth: 130,
      maxWidth: 140,
      headerAlign: 'right',
      align: 'right'
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: IS_PAID,
      minWidth: 40,
      maxWidth: 40,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      sortable: false,
      renderHeader: () => (
        <Tooltip title={translate('labels.payment')}>
          <AttachMoneyIcon
            sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '1.3rem' }}
          />
        </Tooltip>
      )
    },
    //
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: InvoiceSortColumns.INSERTED_BY,
      headerName: translate('labels.insertedBy'),
      minWidth: 120,
      maxWidth: 200,
      align: 'left',
      headerAlign: 'left'
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: InvoiceSortColumns.INSERTED_AT,
      headerName: translate('labels.insertedAt'),
      minWidth: 90,
      maxWidth: 120,
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params) => params.value
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: APPROVERS,
      headerName: translate('labels.approvals'),
      headerAlign: 'left',
      align: 'left',
      minWidth: 115,
      maxWidth: 130,
      sortable: false
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: InvoiceSortColumns.EXPENSE_TYPE,
      headerName: translate('labels.expenseType'),
      minWidth: 100,
      headerAlign: 'left',
      align: 'left'
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: ACTIONS,
      headerName: '',
      minWidth: 40,
      maxWidth: 110,
      align: 'center',
      headerAlign: 'center',
      sortable: false
    }
  ];
  return isInAllCompanies ? columns : [columns[0], ...columns.slice(2)];
};

export const handleSortModelChange = (
  sortModel: GridSortModel,
  setFilters: (value: React.SetStateAction<InvoiceQueryFilters>) => void
) => {
  const hasSortModel = !!sortModel.length;
  const { field, sort } = hasSortModel ? sortModel[0] : { field: '', sort: '' };

  setFilters((prevFilters) =>
    hasSortModel
      ? {
          ...prevFilters,
          sortBy: field as InvoiceSortColumns,
          sortOrder: sort?.toUpperCase() as SortOrder
        }
      : {
          ...prevFilters,
          ...INITIAL_SORT
        }
  );
};

export const getCounterpartyNameRow = (
  row: any,
  column: GridColDef,
  translate: (
    module: string,
    params?: {
      [key: string]: string;
    }
  ) => string
) => {
  return row.isReimbursement && row[column.field]
    ? `${translate('labels.reimburseTo')}: ${row[column.field]}`
    : row[column.field] || '-';
};

export const getInvoiceColumnsWithSkeleton = (row: any, columnName: string) => {
  const rowContent =
    columnName === InvoiceSortColumns.INVOICE_NUMBER
      ? row[columnName] || '-'
      : row[columnName];
  return row[InvoiceSortColumns.STAGE] === InvoiceStages.UPLOADED ? (
    <Box sx={{ width: '100%' }}>
      <Skeleton animation="wave" />
    </Box>
  ) : (
    rowContent
  );
};

export const getInvoiceStepperActiveStep = (currentInvoice: Invoice) => {
  const orderedInvoiceSections = Object.keys(InvoiceSection) as Array<
    keyof typeof InvoiceSection
  >;
  let verifiedSteps = 0;

  orderedInvoiceSections.forEach((section) => {
    switch (section) {
      case InvoiceSection.RECEIVER:
        if (!currentInvoice.isReceiverVerified) {
          return;
        }
        verifiedSteps += 1;
        break;
      case InvoiceSection.SUPPLIER:
        if (!currentInvoice.isSupplierVerified) {
          return;
        }
        verifiedSteps += 1;
        break;
      case InvoiceSection.INVOICE_DATA:
        if (!currentInvoice.isInvoiceDataVerified) {
          return;
        }
        if (currentInvoice.stage === InvoiceStages.APPROVED) {
          verifiedSteps += 2;
          return;
        }
        if (currentInvoice.stage === InvoiceStages.FINALIZED) {
          verifiedSteps += 3;
          return;
        }
        if (currentInvoice.stage === InvoiceStages.REJECTED) {
          verifiedSteps += 4;
          return;
        }
        verifiedSteps += 1;
        break;
      default:
        break;
    }
  });

  return verifiedSteps;
};

export const getCounterPartySearchOptionLabel = (
  option: CounterpartyDropDown
) => {
  if (!option.registrationNumber && !option.vatNumber) {
    return option.name || '';
  }

  return `${option.name || ''} ${`(${option.registrationNumber || ''}${
    option.vatNumber && option.registrationNumber ? ', ' : ''
  }${option.vatNumber || ''})`}`;
};

export const getSupplierDefaultValues = (currentInvoice: Invoice) => ({
  counterpartyName: currentInvoice.counterpartyName || '',
  counterpartyRegistrationNumber:
    currentInvoice.counterpartyRegistrationNumber || '',
  counterpartyVatNumber: currentInvoice.counterpartyVatNumber || '',
  ibanList: currentInvoice.ibanList?.length
    ? currentInvoice.ibanList
    : [INITIAL_IBAN_ITEM],
  isReimbursement: currentInvoice.isReimbursement || false
});

export const getInvoiceDataDefaultValues = (currentInvoice: Invoice) => ({
  invoiceNumber: currentInvoice.invoiceNumber || '',
  invoiceDate: currentInvoice.invoiceDate,
  dueDate: currentInvoice.dueDate,
  vatBase: currentInvoice.vatBase?.toFixed(2) || '',
  vatAmount: currentInvoice.vatAmount?.toFixed(2) || '',
  invoiceAmount: currentInvoice.invoiceAmount?.toFixed(2) || '',
  expenseType: currentInvoice.expenseType?.id,
  currencyId: currentInvoice.currencyId || '',
  documentDescription: currentInvoice.documentDescription || '',
  tags: currentInvoice.tags || [],
  paymentChannel: currentInvoice.paymentChannel || PaymentChannel.COMPANY_CARD,
  documentType: currentInvoice.documentType
});

export const getCreatedDocumentDefaultValues = (currentInvoice: Invoice) => ({
  counterpartyName: currentInvoice.counterpartyName || '',
  counterpartyRegistrationNumber:
    currentInvoice.counterpartyRegistrationNumber || '',
  counterpartyVatNumber: currentInvoice.counterpartyVatNumber || '',
  ibanList: currentInvoice.ibanList?.length
    ? currentInvoice.ibanList
    : [INITIAL_IBAN_ITEM],
  invoiceDate:
    currentInvoice.invoiceDate ||
    moment().format(DATE_FORMATS.displayedDateFormat),
  invoiceAmount: currentInvoice.invoiceAmount?.toFixed(2) || '',
  expenseType: currentInvoice.expenseType?.id,
  currencyId: currentInvoice.currencyId || '',
  isPrepaid: currentInvoice.paymentStatus === PaymentStatus.PREPAID,
  isReimbursement: currentInvoice.isReimbursement || false,
  documentDescription: currentInvoice.documentDescription || '',
  tags: currentInvoice.tags || [],
  paymentChannel: currentInvoice.paymentChannel || PaymentChannel.COMPANY_CARD
});

export const validateIban = (iban: string): boolean => {
  try {
    iban = iban.replace(/\s+/g, '').toUpperCase();
    const rearrangedIban = iban.slice(4) + iban.slice(0, 4);
    const numericIban = rearrangedIban
      .split('')
      .map((char) => {
        const code = char.charCodeAt(0);
        return code >= 65 && code <= 90 ? (code - 55).toString() : char;
      })
      .join('');
    const mod97 = BigInt(numericIban) % BigInt(97);
    return mod97 === BigInt(1);
  } catch (error) {
    return false;
  }
};

export const isCounterpartyVatNumberValid = (
  vatNumber: string,
  companyRegNumber?: string
) => {
  const slicedVatNumber = vatNumber.slice(2, vatNumber.length);
  if (
    vatNumber &&
    companyRegNumber &&
    vatNumber.startsWith(VAT_NUMBER_PREFIX_BULGARIA)
  ) {
    return vatNumber === `BG${companyRegNumber}`;
  }

  if (companyRegNumber && slicedVatNumber !== companyRegNumber) {
    return false;
  }

  return true;
};

export const getContainerHeight = (
  areFiltersVisible: boolean,
  expandedHeight: number,
  isWithoutQuickFilter?: boolean
) => {
  const barHeight = isWithoutQuickFilter ? FILTER_BAR_HEIGHT : -5;
  const expHeight = isWithoutQuickFilter ? expandedHeight : 0;
  const filtersHeight = areFiltersVisible ? expHeight : barHeight;

  return `calc(100% - ${filtersHeight}px)`;
};

export const validateRegistrationNumber = (
  watchedCounterpartyVatNumber: string | undefined,
  watchedCounterpartyRegistrationNumber: string | undefined
) => {
  const vatNumber = watchedCounterpartyVatNumber || '';
  const registrationNumber = watchedCounterpartyRegistrationNumber || '';

  if (!vatNumber) {
    // If vatNumber is missing, registrationNumber should be between 9 and 13 digits or empty
    return registrationNumber === '' || /^\d{9,13}$/.test(registrationNumber);
  }
  if (vatNumber.startsWith('BG')) {
    // If vatNumber starts with "BG", registrationNumber should be between 9 and 13 digits
    return /^\d{9,13}$/.test(registrationNumber);
  }
  // If vatNumber does not start with "BG", registrationNumber should be empty
  return registrationNumber === '';
};

export const validateVatNumber = (
  watchedCounterpartyVatNumber: string | undefined,
  watchedCounterpartyRegistrationNumber: string | undefined
) => {
  const vatNumber = watchedCounterpartyVatNumber || '';
  const registrationNumber = watchedCounterpartyRegistrationNumber || '';

  // If vatNumber starts with "BG", validate the registration number
  if (vatNumber.startsWith('BG')) {
    // Check if the VAT number follows the pattern BG<REGISTRATIONNUMBER>
    const expectedVatNumber = `BG${registrationNumber}`;
    return vatNumber === expectedVatNumber;
  }

  // Otherwise, vatNumber is always valid as long as the other rules are followed
  return true;
};

export const getCreatedDocumentFormValues = (
  selectedCounterPartyId: number | null,
  currentInvoice: Invoice,
  isExisting: boolean,
  getValues: UseFormGetValues<CreatedDocument>
) => {
  const counterpartyId = isExisting
    ? selectedCounterPartyId || currentInvoice.counterpartyId
    : null;

  return {
    counterpartyId,
    counterpartyName: getValues('counterpartyName') || null,
    counterpartyRegistrationNumber:
      getValues('counterpartyRegistrationNumber') || null,
    counterpartyVatNumber: getValues('counterpartyVatNumber') || null,
    invoiceAmount: Number(getValues('invoiceAmount')) || null,
    expenseTypeId: Number(getValues('expenseType')),
    currencyId: getValues('currencyId'),
    isPrepaid: getValues('isReimbursement') ? false : getValues('isPrepaid'),
    invoiceDate: getValues('invoiceDate'),
    isReimbursement: getValues('isReimbursement'),
    documentDescription: getValues('documentDescription'),
    tags: getValues('tags'),
    paymentChannel: getValues('isPrepaid')
      ? getValues('paymentChannel')
      : undefined
  } as InvoicePatch;
};

export const getInvoiceDataFormValues = (
  currentInvoice: Invoice,
  expenseTypeOptions: InvoiceExpenseType[],
  getValues: UseFormGetValues<InvoiceDetails>
) => {
  return {
    invoiceNumber: getValues('invoiceNumber'),
    invoiceDate: getValues('invoiceDate'),
    dueDate: getValues('dueDate'),
    vatBase: Number(getValues('vatBase')),
    vatAmount: Number(getValues('vatAmount')),
    invoiceAmount: Number(getValues('invoiceAmount')),
    expenseType: expenseTypeOptions?.find(
      (expenseType) => expenseType.id === Number(getValues('expenseType'))
    ),
    currencyId: Number(getValues('currencyId')),
    documentDescription: getValues('documentDescription'),
    products: currentInvoice.products,
    tags: getValues('tags')
  };
};

export const getSummaryDataFormValues = (
  currentInvoice: Invoice,
  expenseTypeOptions: InvoiceExpenseType[],
  getValues: UseFormGetValues<InvoiceDetails>
) => {
  return {
    invoiceNumber: getValues('invoiceNumber'),
    invoiceDate: getValues('invoiceDate'),
    dueDate: getValues('dueDate'),
    vatBase: Number(getValues('vatBase')),
    vatAmount: Number(getValues('vatAmount')),
    invoiceAmount: Number(getValues('invoiceAmount')),
    expenseType: expenseTypeOptions?.find(
      (expenseType) => expenseType.id === Number(getValues('expenseType'))
    ),
    currencyId: Number(getValues('currencyId')),
    documentDescription: getValues('documentDescription'),
    products: currentInvoice.products,
    tags: getValues('tags'),
    paymentChannel: getValues('paymentChannel'),
    documentType: getValues('documentType') as DocumentType
  };
};

export const getSaveInvoiceDataFormValues = (
  currentInvoice: Invoice,
  getValues: UseFormGetValues<InvoiceDetails>
) => {
  return {
    invoiceNumber: getValues('invoiceNumber'),
    invoiceDate: getValues('invoiceDate'),
    dueDate: getValues('dueDate'),
    vatBase: Number(getValues('vatBase')),
    vatAmount: Number(getValues('vatAmount')),
    invoiceAmount: Number(getValues('invoiceAmount')),
    expenseTypeId: getValues('expenseType'),
    currencyId: Number(getValues('currencyId')) || 1,
    documentDescription: getValues('documentDescription'),
    products: currentInvoice.products,
    tags: getValues('tags')
  } as InvoicePatch;
};

export const getSupplierDataFormValues = (
  currentInvoice: Invoice,
  isExisting: boolean,
  selectedCounterPartyId: number | null,
  getValues: UseFormGetValues<Invoice>
) => {
  const counterpartyId = isExisting
    ? selectedCounterPartyId || currentInvoice.counterpartyId
    : null;

  return {
    counterpartyId,
    counterpartyName: getValues('counterpartyName'),
    counterpartyRegistrationNumber: getValues('counterpartyRegistrationNumber'),
    counterpartyVatNumber: getValues('counterpartyVatNumber'),
    isReimbursement: getValues('isReimbursement')
  } as InvoicePatch;
};

export const getPaymentIcon = (
  row: any,
  translate: (
    module: string,
    params?: {
      [key: string]: string;
    }
  ) => string
) => {
  if (row.isPrepaid) {
    let label = translate('labels.prePaid');
    if (row.paymentChannel) {
      label = `${label} / ${translate(
        PAYMENT_CHANNELS_TRANSLATION_KEYS[row.paymentChannel as PaymentChannel]
      )}`;
    }

    return (
      <Tooltip title={label}>
        <PriceCheckIcon
          sx={{
            fontSize: '1.1rem'
          }}
        />
      </Tooltip>
    );
  }

  if (row.isPaid) {
    let label = translate('labels.paid');
    if (row.paymentChannel) {
      label = `${label} / ${translate(
        PAYMENT_CHANNELS_TRANSLATION_KEYS[row.paymentChannel as PaymentChannel]
      )}`;
    }

    return (
      <Tooltip title={label}>
        <CreditScoreIcon
          sx={{
            fontSize: '1.1rem'
          }}
        />
      </Tooltip>
    );
  }

  return null;
};

export const getPaymentStatusChip = (
  currentInvoice: Invoice,
  translate: (
    module: string,
    params?: {
      [key: string]: string;
    }
  ) => string
) => {
  if (currentInvoice.paymentStatus === PaymentStatus.PREPAID) {
    return (
      <Chip
        color="success"
        label={translate('labels.prePaid')}
        sx={{
          height: 18,
          fontSize: '0.75rem'
        }}
      />
    );
  }

  if (currentInvoice.paymentStatus !== PaymentStatus.NOT_PAID) {
    return (
      <Chip
        color="success"
        label={translate('labels.paid')}
        sx={{
          height: 18,
          fontSize: '0.75rem'
        }}
      />
    );
  }

  return null;
};
