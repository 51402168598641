/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PaymentStatus {
    NOT_PAID = 'NOT_PAID',
    PREPAID = 'PREPAID',
    MARKED_AS_PAID = 'MARKED_AS_PAID',
    EXPORTED_FOR_PAYMENT = 'EXPORTED_FOR_PAYMENT',
    OPEN_BANKING_WAITING = 'OPEN_BANKING_WAITING',
    OPEN_BANKING_CONFIRMED = 'OPEN_BANKING_CONFIRMED',
    OPEN_BANKING_FAILED = 'OPEN_BANKING_FAILED',
}
