import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { CompanyIban } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { formatMoney } from 'utils/helpers/moneyHelper';
import { ExportForPaymentForm } from 'utils/interfaces/ExportForPaymentForm';

interface SelectIbanFormProps {
  readonly ibanOptions: CompanyIban[];
  readonly onSubmit: (values: ExportForPaymentForm) => void;
  readonly description?: string;
  readonly disabled?: boolean;
  readonly isForOpenBanking?: boolean;
}

export const SelectIbanForm = ({
  ibanOptions,
  onSubmit,
  description,
  disabled,
  isForOpenBanking
}: SelectIbanFormProps) => {
  const { translate } = useTranslations();

  const { control, formState, handleSubmit, setValue } =
    useForm<ExportForPaymentForm>({
      mode: 'onChange'
    });

  useEffect(() => {
    if (ibanOptions.length === 1 && ibanOptions[0].id) {
      setValue('ibanId', ibanOptions[0].id, { shouldValidate: true });
    }
  }, [ibanOptions, setValue]);

  const buttonTooltip = useMemo(() => {
    if (disabled) {
      return '';
    }

    if (!formState.isValid) {
      return translate('errors.ibanSelected');
    }

    return '';
  }, [formState.isValid, translate, disabled]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <Box display="flex">
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.8rem',
            mb: 1,
            textAlign: 'start'
          }}
        >
          {description}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="ibanId"
          control={control}
          rules={{
            required: translate('errors.iban')
          }}
          render={({ field, fieldState: { error } }) => (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <FormControl size="small" error={!!error} sx={{ width: '100%' }}>
                <InputLabel>{`${translate('labels.ibanSelect')}*`}</InputLabel>
                <Select
                  {...field}
                  sx={{ textAlign: 'left' }}
                  label={`${translate('labels.ibanSelect')}*`}
                  error={!!error}
                  value={field.value || ''}
                  fullWidth
                  disabled={disabled}
                >
                  {ibanOptions.map((iban: CompanyIban) => (
                    <MenuItem key={iban.iban} value={iban.id}>
                      {iban.balance !== undefined && iban.balance !== null
                        ? `${iban.iban} (${formatMoney(iban.balance)} ${
                            iban.currency
                          })`
                        : iban.iban}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Tooltip title={buttonTooltip}>
                <Box display="flex">
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={!formState.isValid}
                  >
                    {translate(
                      isForOpenBanking ? 'buttons.pay' : 'buttons.export'
                    )}
                  </Button>
                </Box>
              </Tooltip>
            </Box>
          )}
        />
      </form>
    </Box>
  );
};
