import { useCallback } from 'react';

import {
  OpenBankingIbanConsent,
  OpenBankingPayment,
  OpenBankingService
} from 'openapi';

import { useInterceptor } from 'hooks/useInterceptor';

export const useOpenBankingController = () => {
  const { interceptRequest } = useInterceptor();

  const { consentIban, deleteIban, signUp, signOut, initiatePayment } =
    OpenBankingService;

  const consentIbanByCompanyId = useCallback(
    (companyId: number, ibanId: number, requestBody: OpenBankingIbanConsent) =>
      interceptRequest(consentIban, {}, companyId, ibanId, requestBody),
    [consentIban]
  );

  const deleteIbanByCompanyId = useCallback(
    (companyId: number, ibanId: number) =>
      interceptRequest(deleteIban, {}, companyId, ibanId),
    [deleteIban]
  );

  const signUpToOpenBanking = useCallback(
    (companyId: number) => interceptRequest(signUp, {}, companyId),
    [signUp]
  );

  const signOutFromOpenBanking = useCallback(
    (companyId: number) => interceptRequest(signOut, {}, companyId),
    [signOut]
  );

  const initiatePaymentByCompanyId = useCallback(
    (companyId: number, ibanId: number, requestBody: OpenBankingPayment) =>
      interceptRequest(initiatePayment, {}, companyId, ibanId, requestBody),
    [initiatePayment]
  );

  return {
    consentIbanByCompanyId,
    deleteIbanByCompanyId,
    signUpToOpenBanking,
    signOutFromOpenBanking,
    initiatePaymentByCompanyId
  };
};
