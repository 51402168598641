import WarningIcon from '@mui/icons-material/Warning';
import { Box, Paper, Typography, Divider } from '@mui/material';
import { grey, yellow } from '@mui/material/colors';
import { useCallback, useEffect, useState } from 'react';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';

import { ExpenseType, Invoice, InvoiceSection } from 'openapi';

import { LabelValueField } from 'components/shared/LabelField/LabelValueField';

import { useTranslations } from 'context/TranslationContext';

import { InvoiceSectionKey } from 'utils/interfaces/InvoiceProps';

import { ActionButtons } from './ActionButtons';
import { AdditionalInvoiceFields } from './ReceiverForm';

interface CreateCounterpartyOverviewProps {
  currentInvoice: Invoice;
  counterpartyFormData?: Invoice;
  cpIsNotFoundInDatabase: boolean;
  handleBack?: () => void;
  handleCreate?: (
    values: Invoice & AdditionalInvoiceFields,
    section: InvoiceSectionKey
  ) => Promise<void>;
}

export const CreateCounterpartyOverview = ({
  currentInvoice,
  counterpartyFormData,
  cpIsNotFoundInDatabase,
  handleBack,
  handleCreate
}: CreateCounterpartyOverviewProps) => {
  const { translate } = useTranslations();
  const { getExpenseTypes } = useAdditionalFieldsController();

  const [expenseTypeOptions, setExpenseTypeOptions] = useState<ExpenseType[]>(
    []
  );

  const {
    counterpartyName,
    counterpartyRegistrationNumber,
    counterpartyVatNumber
  } = currentInvoice;

  const fetchExpenseTypes = useCallback(async () => {
    const expenseTypeOptionValues = await getExpenseTypes(
      Number(currentInvoice.companyId)
    );
    setExpenseTypeOptions(expenseTypeOptionValues);
  }, [getExpenseTypes, currentInvoice]);

  useEffect(() => {
    if (expenseTypeOptions.length) {
      return;
    }

    fetchExpenseTypes();
  }, [fetchExpenseTypes]);

  const handleCreateCounterparty = () => {
    const counterpartyInfo = counterpartyFormData as Invoice;
    if (!currentInvoice.counterpartyId) {
      counterpartyInfo.counterpartyId = currentInvoice.counterpartyId;
    }
    if (handleCreate) {
      handleCreate(counterpartyInfo, InvoiceSection.SUPPLIER);
    }
  };

  return (
    <Box sx={{ width: '100%', pt: 2.5 }}>
      <ActionButtons
        handleBack={handleBack}
        handleCreate={handleCreateCounterparty}
      />

      <Paper elevation={3} sx={{ padding: 1, backgroundColor: '#fefefe' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
          <WarningIcon
            sx={{
              fontSize: 28,
              color: yellow[900],
              mx: 2
            }}
          />
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 600,
              textAlign: 'start',
              color: grey[800]
            }}
          >
            {translate('messages.newCounterpartyWillBeCreated', {
              companyName: currentInvoice.shortNameCompany || ''
            })}
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <LabelValueField
          label={translate('labels.counterpartyName')}
          value={counterpartyName || '-'}
        />

        <LabelValueField
          label={translate('labels.companyRegistrationNumber')}
          value={counterpartyRegistrationNumber || '-'}
        />

        <LabelValueField
          label={translate('labels.vatNumber')}
          value={counterpartyVatNumber || '-'}
        />

        {cpIsNotFoundInDatabase && (
          <>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
              <WarningIcon
                sx={{
                  fontSize: 28,
                  color: yellow[900],
                  mx: 2
                }}
              />
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 600,
                  textAlign: 'start',
                  color: grey[800]
                }}
              >
                {translate('messages.counterpartyNotInDatabase')}
              </Typography>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};
