import { DialogTitle, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { dialogTitle } from 'styles/components/ModalStyle';

interface PaymentMethodCardProps {
  readonly children: JSX.Element;
  readonly headerText: string;
  readonly tooltipText: string;
  readonly disabled?: boolean;
}

export const PaymentMethodCard = ({
  children,
  headerText,
  tooltipText,
  disabled
}: PaymentMethodCardProps) => {
  return (
    <Tooltip title={tooltipText}>
      <Card
        sx={{
          height: '12rem',
          width: '25rem',
          bgcolor: disabled ? 'grey.500' : 'white',
          display: 'flex',
          flexDirection: 'column',
          boxShadow:
            '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
        }}
      >
        <DialogTitle sx={{ ...dialogTitle, textTransform: 'uppercase' }}>
          {headerText}
        </DialogTitle>
        <CardContent
          sx={{
            p: '1rem 1.5rem',
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {children}
        </CardContent>
      </Card>
    </Tooltip>
  );
};
